import React, { ReactElement, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Button,
  Chip,
  createStyles,
  Grid,
  StyleRules,
  TextField,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Image } from 'antd';

import { consultantGetProjectBidById } from '../../actions/projectBidAction';
import { ConsultantApplyingBiddingProjectDialog } from '../../components/Dialog';
import { formatDate } from '../../utils/dateFormat';
import { MatchArea } from '../../utils/util';
import { createCompanySupplementInfo } from '../company/company.model';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      maxWidth: '80%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: '7%',
    },

    bottomButton: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    deleteButton: {
      justifyContent: 'end',
    },
    dataGridTable: {
      height: 200,
      width: '100%',
    },
    childrenItem: {
      background: '#ffffff',
      borderRadius: '3px',
      margin: '0 auto',
      padding: '0.5rem 0',
    },
    spaced: {
      margin: '0 1rem ',
    },
    titlePosition: {
      padding: '0 6px',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '12px',
      position: 'absolute',
      top: '-10px',
      left: '6px',
      backgroundColor: '#FFFFFF',
    },
    cardWidth: {
      width: '100%',
      height: '56px',
      paddingTop: '8px',
    },
    itemML: {
      lineHeight: '42px',
    },
    salaryBorder: {
      padding: '10px',
      background: '#f5f5f5',
    },
    buttonPosition: {
      margin: '1rem',
    },
    borderMargin: {
      margin: '0.5rem 0',
    },
    bidPeople: {
      padding: '1rem',
      margin: '0.5rem 0',
      background: '#f5f5f5',
    },
    borderMarginBox: {
      margin: '0.5rem 0',
      borderBottom: '1px solid rgb(64, 64, 64,0.3)',
      borderRadius: '3px',
      padding: '1rem',
    },
    borderMarginTwo: {
      height: '56px',
    },
    iconLineHeight: {
      margin: '1rem 0',
    },
    remuneration: {
      padding: '1rem',
      borderBottom: '1px solid rgba(32, 35, 42, 0.3)',
    },
  });

const applyingBiddingInfoView = ({
  classes,
  onClose,
  StatusValue,
  rowDataId,
}: Props): ReactElement => {
  const dispatch = useDispatch();
  const [isCancelDialog, setIsCancelDialog] = useState(false);
  const [requestData, setRequestData] = useState<Bidding_info>();
  const {
    // setValue: setSupplementInfoValue,
    control: controlSupplementInfo,
    // getValues: getSupplementInfoValues,
    // trigger: triggerSupplementInfo,
    // reset: restSupplementInfo,
  } = useForm({
    defaultValues: {
      CompanySupplementInfo: createCompanySupplementInfo(),
    },
    mode: 'onChange',
  });

  const {
    // isDirty: isSupplementInfoDirty,
    errors: errorsSupplementInfo,
    isValid: isSupplementInfoValid,
  } = useFormState({
    control: controlSupplementInfo,
  });
  errorsSupplementInfo;
  isSupplementInfoValid;

  const fetchData = async () => {
    const data = await dispatch(consultantGetProjectBidById(rowDataId));
    setRequestData(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Grid xs={11} className={classes.childrenItem} container justifyContent="center">
        <Grid item xs={11} className={classes.borderMarginBox} container justifyContent="center">
          <Grid item xs={12} justifyContent="center" container>
            <h2>项目信息</h2>
          </Grid>
          <Grid item xs={12} className={classes.borderMargin}>
            名称：{requestData?.request?.projectName}
          </Grid>
          <Grid item xs={12} className={classes.borderMargin}>
            简介：{requestData?.request?.projectContent}
          </Grid>
          <Grid item xs={12} className={classes.borderMargin}>
            地址：
            {MatchArea({
              pCode: requestData?.request?.workLocationProvince || '11',
              cCode: requestData?.request?.workLocationCity || '1101',
              aCode: requestData?.request?.workLocationDistrict || '110101',
            })}
          </Grid>
          <Grid item xs={12} className={classes.borderMargin}>
            工作量：{requestData?.request?.workload}
          </Grid>
          <Grid item xs={12} className={classes.borderMargin}>
            时间：
            {requestData?.request?.projectStartTime
              ? formatDate(new Date(`${requestData?.request?.projectStartTime}`), 'yyyy-MM-dd')
              : requestData?.request?.projectStartAsap}
            {requestData?.request?.projectEndTime && <span className={classes.spaced}>-</span>}
            {requestData?.request?.projectEndTime &&
              formatDate(new Date(`${requestData?.request?.projectEndTime}`), 'yyyy-MM-dd')}
          </Grid>

          <Grid item xs={12} className={classes.borderMargin}>
            项目酬劳：
            <p className={classes.remuneration}>
              {requestData?.request?.remunerationInfo?.monthlySalaryMin && (
                <div>
                  <p className={classes.displayFlex}>
                    <Chip
                      label="月度结算"
                      className={classes.chip}
                      color="primary"
                      variant="outlined"
                    />
                  </p>
                  <p className={classes.inlineBox}>
                    预计最低酬劳：{requestData?.request?.remunerationInfo?.monthlySalaryMin}
                    <span className={classes.spaced}></span> 预计最高酬劳：
                    {requestData?.request?.remunerationInfo?.monthlySalaryMax}
                  </p>
                  {requestData?.request?.remunerationInfo?.monthlySalaryDescription && (
                    <p className={classes.inlineBox}>
                      结算周期：{requestData?.request?.remunerationInfo?.monthlySalaryDescription}
                    </p>
                  )}
                </div>
              )}
              {requestData?.request?.remunerationInfo?.singleSalaryMin && (
                <div>
                  <p className={classes.displayFlex}>
                    <Chip
                      label="单项结算"
                      className={classes.chip}
                      color="primary"
                      variant="outlined"
                    />
                  </p>
                  <p className={classes.inlineBox}>
                    预计最低酬劳：{requestData?.request?.remunerationInfo?.singleSalaryMin}
                    <span className={classes.spaced}></span> 预计最高酬劳：
                    {requestData?.request?.remunerationInfo?.singleSalaryMax}
                  </p>
                  {requestData?.request?.remunerationInfo?.singleSalaryDescription && (
                    <p className={classes.inlineBox}>
                      结算周期：{requestData?.request?.remunerationInfo?.singleSalaryDescription}
                    </p>
                  )}
                </div>
              )}
              {requestData?.request?.remunerationInfo?.otherSalaryDescription && (
                <div>
                  <p className={classes.displayFlex}>
                    <Chip
                      label="其他结算"
                      className={classes.chip}
                      color="primary"
                      variant="outlined"
                    />
                  </p>
                  <p className={classes.inlineBox}>
                    酬劳描述：{requestData?.request?.remunerationInfo?.otherSalaryDescription}
                  </p>
                  {requestData?.request?.remunerationInfo?.otherSalaryCycle && (
                    <p className={classes.inlineBox}>
                      结算周期：{requestData?.request?.remunerationInfo?.otherSalaryCycle}
                    </p>
                  )}
                </div>
              )}
            </p>
          </Grid>
          {requestData?.companyBidComment ? (
            <Grid item xs={12} className={classes.borderMargin}>
              企业备注：{requestData?.companyBidComment}
            </Grid>
          ) : (
            ''
          )}
          {requestData?.consultantBidAdvantages ? (
            <Grid item xs={12} className={classes.borderMargin}>
              顾问备注：{requestData?.consultantBidAdvantages}
            </Grid>
          ) : (
            ''
          )}
          {requestData?.attachments ? (
            <Grid item xs={12} className={classes.borderMargin}>
              {requestData.attachments.map(item => {
                return (
                  <Image preview={false} width={100} height={100} src={item.file} key={item.file} />
                );
              })}
            </Grid>
          ) : null}
          <Grid item xs={12} className={classes.borderMargin}>
            联系方式：{requestData?.request?.workLocationDistrict}
          </Grid>
          {/* <Grid item xs={12} className={classes.borderMargin}>
              公司信息
            </Grid> */}
          {requestData?.request?.company?.verification?.basicInfo?.registrationName && (
            <Grid item xs={12} className={classes.borderMargin}>
              公司：{requestData?.request?.company?.verification?.basicInfo?.registrationName}
            </Grid>
          )}
          {/* <Grid item xs={12} className={classes.borderMargin}>
              联系邮箱：{requestData?.request?.company?.verification?.basicInfo?.contactEmail}
            </Grid> */}
        </Grid>

        {StatusValue != 0 && (
          <Grid item xs={11} className={classes.borderMarginBox} container justifyContent="center">
            <Grid item xs={11} justifyContent="center" container>
              <h2>邀约信息</h2>
            </Grid>
            <Grid item xs={11}>
              <h4>尊敬的[对方姓名/称呼]：</h4>
            </Grid>
            <Grid item xs={11} className={classes.borderMargin}>
              我谨代表[您的组织/公司/团队]
            </Grid>
            <Grid item xs={11} className={classes.borderMargin}>
              邀请您参加[活动名称]
            </Grid>
            <Grid item xs={11} className={classes.borderMargin}>
              活动详情如下：[日期、时间、地点、活动内容简述]
            </Grid>
            <Grid item xs={11} className={classes.borderMargin}>
              其他附加
            </Grid>
            <Grid item xs={11} className={classes.borderMargin}>
              我们相信您的参与将为活动增添光彩，期待您的光临
            </Grid>
            <Grid item xs={11} className={classes.borderMargin}>
              [联系方式]
            </Grid>
          </Grid>
        )}

        {StatusValue == 1 && (
          <Grid item xs={11} className={classes.borderMarginBox} container>
            <Grid item xs={12} container justifyContent="center">
              <h2>确认信息</h2>
            </Grid>

            <Grid item xs={12} className={classes.borderMargin} container>
              <Grid item xs={12} container justifyContent="center">
                <Grid xs={1}></Grid>
                <Grid xs={10}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    multiline
                    id="LastName"
                    label="我的优势"
                    minRows={4}
                  />
                </Grid>
                <Grid xs={1}>
                  <ErrorOutlineIcon className={classes.iconLineHeight} />
                </Grid>
              </Grid>
            </Grid>

            <Grid xs={12} container className={classes.borderMargin} justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={classes.buttonPosition}
                onClick={() => {
                  setIsCancelDialog(true);
                }}
              >
                确认
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>

      <ConsultantApplyingBiddingProjectDialog
        isOpen={isCancelDialog}
        handleEdit={() => {
          setIsCancelDialog(false);
          onClose ? onClose() : '';
        }}
        handleDialogClose={() => {
          setIsCancelDialog(false);
          onClose ? onClose() : '';
        }}
      ></ConsultantApplyingBiddingProjectDialog>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
  StatusValue?: number;
  rowDataId?: number;
}

export default withStyles(styles)(applyingBiddingInfoView);
